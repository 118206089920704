<template>
  <div class="content-wrapper">
    <h1>Anagrafiche Clienti</h1>

    <b-card class="mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <span></span>
        <b-button size="sm" class="mb-2" v-b-toggle.collapse-advancedFilter>
          <b-icon icon="filter" aria-hidden="true"></b-icon>
          Filtri
        </b-button>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span></span>
        <b-button size="sm" class="mb-2" v-b-toggle.sidebar-anagrafica>
          <b-icon icon="plus" aria-hidden="true"></b-icon>
          Nuova Anagrafica
        </b-button>
      </div>
      <b-collapse
        id="collapse-advancedFilter"
        v-model="advancedFilter_visible"
        class="mt-2"
      >
        <b-card class="mb-4">
          <div class="d-flex my-2">
            <h4>Filtri</h4>
          </div>

          <b-row>
            <b-col sm="12">
              <div class="my-1">
                <b-row align-v="center">
                  <b-form-checkbox v-model="objectToPost.anagraficaYoube"
                    >Anagrafica Yoube</b-form-checkbox
                  >
                </b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Nome</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.nome"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Cognome</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.cognome"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Ragione Sociale</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.ragioneSociale"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Partita Iva</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.partitaIva"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Codice Fiscale</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.codiceFiscale"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Email</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.email"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Telefono</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.telefono"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Cellulare</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.cellulare"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Città</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.citta"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Provincia</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.provincia"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Regione</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.regione"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Nazione</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.nazione"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="4">
              <div class="p-2">
                <b-row><span>Note</span></b-row>
                <b-row
                  ><b-form-input
                    type="text"
                    v-model="objectToPost.note"
                  ></b-form-input
                ></b-row>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end align-items-center">
            <div class="d-flex justify-content-between align-items center">
              <b-button @click="resetObjectToPost" class="mb-2 mr-2">
                <b-icon
                  icon="arrow-counterclockwise"
                  aria-hidden="true"
                ></b-icon>
                Resetta filtri
              </b-button>
              <b-button
                @click="getData"
                class="mb-2"
                v-b-toggle.collapse-advancedFilter
              >
                <b-icon icon="search" aria-hidden="true"></b-icon>
                Applica filtri
              </b-button>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </b-card>

    <b-overlay rounded="sm" class="overlay-placeholder">
      <b-card>
        <div v-if="loadingItems" class="w-100">
          <SkeletonTable></SkeletonTable>
        </div>
        <div v-else>
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div>
              <div class="d-flex justify-content-start align-items-center mb-2">
                <span class="mr-2">Seleziona:</span>

                <b-button-group>
                  <b-button size="sm" @click="selectAllRows">Tutto</b-button>
                  <b-button size="sm" @click="clearSelected">Nulla</b-button>
                </b-button-group>
              </div>
              <div class="font-xs" style="color:gray">
                Tieni premuto 'Ctrl' per selezionare più righe, tieni premuto
                'Shift' per selezionare un range di righe.
              </div>
            </div>
            <div>
              <b-button @click="showSelezionaAgenteModal = true"
                >Assegna la selezione a...</b-button
              >
            </div>
          </div>
          <div class="w-100">
            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              ref="selectableTable"
              :selectable="true"
              select-mode="range"
              @row-selected="onRowSelected"
            >
              <template #cell(azioni)="row">
                <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <b-icon icon="gear-fill"></b-icon>
                  </template>
                  <b-dropdown-item
                    href="#"
                    v-b-toggle.sidebar-anagrafica
                    @click="
                      currentItem = row.item
                      tmpItem = JSON.parse(JSON.stringify(row.item))
                    "
                    >Edit</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="#"
                    @click="
                      currentItem = row.item
                      showDeleteModal = true
                    "
                    >Delete</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="mt-4 mb-1"
          ></b-pagination>
        </div>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-anagrafica"
      right
      shadow
      lazy
      @hidden="resetObjectToPost"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentItem">Modifica Anagrafica</h4>
        <h4 v-else>Nuova Anagrafica</h4>
      </div>

      <div class="m-1 px-4">
        <b-row>
          <b-col sm="12">
            <div class="my-1">
              <b-row align-v="center">
                <b-form-checkbox v-model="tmpItem.anagraficaYoube"
                  >Anagrafica Yoube</b-form-checkbox
                >
              </b-row>
            </div>
          </b-col>
          <!-- Personal Information -->

          <b-col sm="12">
            <h4 class="mb-0 mt-4">Informazioni personali</h4>
          </b-col>

          <b-col sm="12" md="6">
            <div class="p-2">
              <b-row><span>Nome</span></b-row>
              <b-row
                ><b-form-input type="text" v-model="tmpItem.nome"></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6">
            <div class="p-2">
              <b-row><span>Cognome</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.cognome"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <!-- Company Information -->
          <b-col sm="12">
            <h4 class="mb-0 mt-4">Informazioni aziendali</h4>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Tipo anagrafica</span></b-row>
              <b-row>
                <b-skeleton v-if="loadingTipoAnagrafica"></b-skeleton>
                <b-form-select
                  v-else
                  :options="tipoAnagraficaOptions"
                  v-model="tmpItem.idTipoAnagrafica"
                ></b-form-select>
              </b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="8">
            <div class="p-2">
              <b-row><span>Ragione Sociale</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.ragioneSociale"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <!-- User Information -->
          <b-col sm="12">
            <h4 class="mb-0 mt-4">Informazioni dell'utente</h4>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Utente</span></b-row>
              <b-row>
                <b-skeleton v-if="loadingUtente"></b-skeleton>
                <b-form-select
                  v-else
                  :options="utenteOptions"
                  v-model="tmpItem.idUtente"
                ></b-form-select>
              </b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Agente</span></b-row>
              <b-row>
                <b-skeleton v-if="loadingUtente"></b-skeleton>
                <b-form-select
                  v-else
                  :options="utenteOptions"
                  v-model="tmpItem.idPadre"
                ></b-form-select>
              </b-row>
            </div>
          </b-col>

          <!-- Contact Information -->
          <b-col sm="12">
            <h4 class="mb-0 mt-4">Informazioni di contatto</h4>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Partita Iva</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.partitaIva"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Codice Fiscale</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.codiceFiscale"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Email</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.email"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Indirizzo</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.indirizzo"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Telefono</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.telefono"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Cellulare</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.cellulare"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <!-- Other Information -->
          <b-col sm="12">
            <h4 class="mb-0 mt-4">Altre informazioni</h4>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Sede Legale</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.sedeLegale"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Gruppo d'acquisto</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.gruppoDAquisto"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Indirizzo di consegna</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.indirizzoDiConsegna"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Codice univoco SDI</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.codiceUnivocoSDI"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <!-- Additional Information -->
          <b-col sm="12">
            <h4 class="mb-0 mt-4">Informazioni aggiuntive</h4>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>PEC</span></b-row>
              <b-row
                ><b-form-input type="text" v-model="tmpItem.pec"></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Referente commerciale</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.referenteCommerciale"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Referente amministrazione</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.referenteAmministrazione"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Banca d'appoggio</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.bancaDAppoggio"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>ABi e CAB</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.ABIeCAB"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <!-- Location Information -->
          <b-col sm="12">
            <h4 class="mb-0 mt-4">Informazioni geografiche</h4>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Città</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.citta"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Provincia</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.provincia"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Regione</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.regione"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <div class="p-2">
              <b-row><span>Nazione</span></b-row>
              <b-row
                ><b-form-input
                  type="text"
                  v-model="tmpItem.nazione"
                ></b-form-input
              ></b-row>
            </div>
          </b-col>

          <!-- Notes -->
          <b-col sm="12">
            <h4 class="mb-0 mt-4">Note</h4>
          </b-col>
          <b-col sm="12">
            <div class="p-2">
              <b-row><span>Note</span></b-row>
              <b-row
                ><b-form-textarea
                  type="text"
                  v-model="tmpItem.note"
                ></b-form-textarea
              ></b-row>
            </div>
          </b-col>
        </b-row>
        <div class="mt-5 mb-5">
          <b-button
            v-if="loadingSalvaItem"
            squared
            variant="primary"
            size="lg"
            class="big-button"
            disabled
            ><b-spinner small type="grow"></b-spinner> Caricamento...</b-button
          >
          <b-button
            v-else
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
          >
            Salva
          </b-button>
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showSelezionaAgenteModal"
      title="Assegna Anagrafiche"
      ok-title="Confirm"
      cancel-title="Cancel"
      @ok="assegnaSelezioneToPost"
    >
      <b-form-group label-cols="4" label-align="right" label="Agente">
        <b-form-select
          v-model="tmpUtenteAssegnato"
          :options="utenteOptions"
          required
        ></b-form-select>
      </b-form-group>
    </b-modal>
    <b-modal
      v-model="showDeleteModal"
      title="Attenzione!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetObjectToPost"
      @ok="deleteItem(currentItem.id)"
      >Confermi l'azione di eliminazione

      <span v-if="currentItem && currentItem.nome && currentItem.cognome"
        >di {{ currentItem.nome + " " + currentItem.cognome }}</span
      >

      ?</b-modal
    >
  </div>
</template>

<script>
import SkeletonTable from "../../components/SkeletonTable.vue"
import AxiosService from "../../axiosServices/AxiosService"

export default {
  name: "AnagraficaClienti",
  components: {
    SkeletonTable,
  },
  created() {
    this.getData()
    this.getUtenteOptions()
    this.getTipoAnagraficaOptions()
  },
  data() {
    return {
      items: [],
      itemsSelected: [],
      tmpUtenteAssegnato: null,
      fields: [],
      loadingItems: false,
      loadingSalvaItem: false,
      loadingTipoAnagrafica: false,
      loadingUtente: false,
      utenteOptions: [],
      tipoAnagraficaOptions: [],
      advancedFilter_visible: false,
      showDeleteModal: false,
      showSelezionaAgenteModal: false,
      currentPage: 1,
      perPage: 10,
      objectToPost: {
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
        sedeLegale: "",
        gruppoDAquisto: "",
        indirizzoDiConsegna: "",
        codiceUnivocoSDI: "",
        pec: "",
        referenteCommerciale: "",
        referenteAmministrazione: "",
        bancaDAppoggio: "",
        ABIeCAB: "",
      },
      tmpItem: {
        id: null,
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        indirizzo: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
        sedeLegale: "",
        gruppoDAquisto: "",
        indirizzoDiConsegna: "",
        codiceUnivocoSDI: "",
        pec: "",
        referenteCommerciale: "",
        referenteAmministrazione: "",
        bancaDAppoggio: "",
        ABIeCAB: "",
      },
      currentItem: null,
    }
  },
  methods: {
    assegnaSelezioneToPost() {
      console.log(
        "ID ANAGRAFICHE ASSEGNATE -> ",
        this.itemsSelected.map((item) => item.id)
      )
      console.log("ID AGENTE CHE LE RICEVE -> ", this.tmpUtenteAssegnato)
    },
    onRowSelected(event) {
      this.itemsSelected = event
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    resetObjectToPost() {
      this.objectToPost = {
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
        sedeLegale: "",
        gruppoDAquisto: "",
        indirizzoDiConsegna: "",
        codiceUnivocoSDI: "",
        pec: "",
        referenteCommerciale: "",
        referenteAmministrazione: "",
        bancaDAppoggio: "",
        ABIeCAB: "",
      }

      this.tmpItem = {
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
        sedeLegale: "",
        gruppoDAquisto: "",
        indirizzoDiConsegna: "",
        codiceUnivocoSDI: "",
        pec: "",
        referenteCommerciale: "",
        referenteAmministrazione: "",
        bancaDAppoggio: "",
        ABIeCAB: "",
      }
    },
    getData() {
      this.loadingItems = true

      this.items.length > 0 ? this.items.splice(0) : null
      this.fields.length > 0 ? this.fields.splice(0) : null

      const service = new AxiosService("Ecommerce/GetAnags")

      service
        .create(this.objectToPost)
        .then((res) => {
          this.items = res
        })
        .finally(() => {
          this.loadingItems = false
          //populate this.fields with the keys of the first item
          if (this.items.length > 0) {
            this.fields = Object.keys(this.items[0]).filter((field) => {
              if (
                field === "id" ||
                field === "idTipoAnagrafica" ||
                field === "idUtente" ||
                field === "idPadre" ||
                field === "indirizzo" ||
                field === "provincia" ||
                field === "regione" ||
                field === "sedeLegale" ||
                field === "gruppoDAquisto" ||
                field === "indirizzoDiConsegna" ||
                field === "pec" ||
                field === "codiceUnivocoSDI" ||
                field === "referenteCommerciale" ||
                field === "referenteAmministrazione" ||
                field === "bancaDAppoggio" ||
                field === "ABIeCAB" ||
                field === "cognome"
              ) {
                return false
              } else {
                return true
              }
            })
          }

          //remove from this.fields the keys that you don't want to show
          this.fields = this.fields.filter((field) => {
            return (
              field !== "id" &&
              field !== "idTipoAnagrafica" &&
              field !== "idPadre" &&
              field !== "idUtente"
            )
          })

          //push to this.fields "actions" key
          this.fields.push("azioni")
        })
    },
    saveItem() {
      this.loadingSalvaItem = true

      let item = { ...this.tmpItem }
      const servicePOST = new AxiosService("Ecommerce/AddAnag")
      const servicePUT = new AxiosService("Ecommerce/EditAnag")

      if (item.id) {
        servicePUT
          .update(item)
          .then(() => {
            this.$successToast()
          })
          .finally(() => {
            this.loadingSalvaItem = false
            this.$root.$emit("bv::toggle::collapse", "sidebar-anagrafica")
            this.getData()
            this.resetObjectToPost()
          })
      } else {
        servicePOST
          .create(item)
          .then(() => {
            this.$successToast()
          })
          .finally(() => {
            this.loadingSalvaItem = false
            this.$root.$emit("bv::toggle::collapse", "sidebar-anagrafica")
            this.getData()
            this.resetObjectToPost()
          })
      }
    },
    deleteItem(id) {
      const service = new AxiosService("Ecommerce/DeleteAnag")
      service
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            )
            this.$successToast()
          } else {
            this.$errorToast()
            return false
          }
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
        .finally(() => {
          this.getData()
        })
    },
    getTipoAnagraficaOptions() {
      this.loadingTipoAnagrafica = true
      this.tipoAnagraficaOptions.splice(0)
      const service = new AxiosService("Ecommerce/GetClientTypes")
      service
        .read()
        .then((res) => {
          this.tipoAnagraficaOptions = res.map((x) => {
            return {
              value: x.id,
              text: x.nome,
            }
          })
        })
        .finally(() => {
          this.loadingTipoAnagrafica = false
        })
    },
    getUtenteOptions() {
      this.loadingUtente = true
      this.utenteOptions.splice(0)
      const service = new AxiosService("Option/User")
      service
        .read()
        .then((res) => {
          this.utenteOptions = res
        })
        .finally(() => {
          this.loadingUtente = false
        })
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0
    },
  },
}
</script>
